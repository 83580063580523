


































































































































































































































































































import _ from "lodash";
import rest from "@/rest";
import router from "@/router";
import { Component, Vue, Watch } from "vue-property-decorator";
import EditGroup from "@/components/Groups/EditGroup.vue";
import EditSubGroup from "@/components/Groups/EditSubGroup.vue";
import { namespace } from "vuex-class";
import { vuex } from "@/store";
import { List } from "linq-collections";
// interfaces and classes
import { PortalUserVM, OrganizationVm } from "@/interfaces/PortalUserVM";
import { GroupExtVm, SubGroupVm } from "@/interfaces/GroupExtVm";
import { GroupExtVmClass } from "@/classes/GroupExtVmClass";
import { signalrEventBus } from "@/main";
import { ParamDictionary } from "@/interfaces/ParamDictionary";

const auth = namespace("auth");
const globals = namespace("globals");

@Component({
  components: {
    EditGroup,
    EditSubGroup
  }
})
export default class GroupsView extends Vue {
  // @auth.Getter isAdmin: any;
  // @auth.Getter isOrgAdmin: any;
  // @auth.Getter isGroupAdmin: any;
  @globals.Getter selectedOrg!: OrganizationVm;
  @globals.Getter selectedGroup!: GroupExtVm;
  @globals.Getter selectedSubGroup!: SubGroupVm;

  breadcrumbItems = [
    { text: 'Start', disabled: false, href: '/home' },
    { text: 'Gruppen', disabled: true, href: '/groups' },
  ];

  busy = false;
  dynGroupUpdateBusy = false;
  dynGroupUpdateProgress = 0;
  showEditGroupDialog = false;
  showDeleteGroupDialog = false;
  organizations: OrganizationVm[] = [];
  localSelectedOrg: OrganizationVm | null = null;
  isNewGroup = true;
  editGroup: GroupExtVm = new GroupExtVmClass();
  // SubGroup
  showEditSubGroupDialog: boolean = false;
  showDeleteSubGroupDialog: boolean = false;
  defaultSubGroup: SubGroupVm = {
    id: 0,
    parentGroupId: 0,
    parentGroupName: "",
    name: "",
    allowModify: true,
    userCount: 0,
    users: [] as  PortalUserVM[],
    lastChangedBy: "",
    lastChangeDate: new Date()
  }
  editSubGroup: SubGroupVm =  _.cloneDeep(this.defaultSubGroup);
  newClassFormValid: boolean = true;
  groups: GroupExtVm[] = [];

  $refs!: {
    // addNewPupilForm: HTMLFormElement;
  };

  async mounted() {
    await this.updateOrgList();
    this.updateGroupList();
    await this.$l3rnOnlineHub.ensureConnection();
  }

  created() {
    // SignalR event listener
    signalrEventBus.$on('dynamicGroupUpdateProgress', this.onDynGroupUpdateProgress);
    signalrEventBus.$on('dynamicGroupUpdateFinished', this.onDynGroupUpdateFinished);
  }

  beforeDestroy() {
    // clearAllBodyScrollLocks();
    // Make sure to cleanup SignalR event handlers when removing the component
    signalrEventBus.$off('dynamicGroupUpdateProgress', this.onDynGroupUpdateProgress);
    signalrEventBus.$off('dynamicGroupUpdateFinished', this.onDynGroupUpdateFinished);
  }

  // SignalR events
  onDynGroupUpdateProgress(progress: number) {
    this.dynGroupUpdateBusy = true;
    this.dynGroupUpdateProgress = progress;
  }

  onDynGroupUpdateFinished(response: any) {
    this.dynGroupUpdateBusy = false;
    // if (response.status == "SUC") {
    //   vuex.ux.SB_SUCCESS({
    //     message: response.message,
    //     timeout: 8000
    //   });
    // } else {
    //   vuex.ux.SB_FAILURE({
    //     message: response.message,
    //     timeout: 0
    //   });
    // }
  }

  async updateOrgList() {
    // this.organizations = await rest.url("groupAdmin/getGroupAdminOrganizations").get();
    let params = <ParamDictionary>{dictionary: {}};
    params.dictionary!["LoadAllOrgs"] = "false";
    params.dictionary!["AddAllOrgsSelection"] = "false";
    this.organizations = await rest.url("userManagement/loadOrganizations").post(params);

    if (this.organizations.length > 0) {
      if (this.selectedOrg) {
        let org = new List(this.organizations).singleOrDefault(s => s.id == this.selectedOrg.id);
        if (org) {
          this.localSelectedOrg = org;
          return;
        }
      }
      vuex.globals.setOrg(this.organizations[0]);
    } else {
      vuex.globals.setOrg(null);
    }
    this.localSelectedOrg = this.selectedOrg;
  }

  async onOrgChanged() {
    vuex.globals.setOrg(this.localSelectedOrg);
    this.updateGroupList();
  }

  async updateGroupList() {
    if (this.selectedOrg == null) return;
    this.groups = await rest
      .query({ orgId: this.selectedOrg.id, asGroupAdmin: true })
      .url("groupAdmin/getMyGroupsExt").get();
  }

  onAddGroup() {
    vuex.globals.setGroup(null);
    this.editGroup = new GroupExtVmClass();
    this.isNewGroup = true;
    this.showEditGroupDialog = true;
  }

  onShowUsers(selectedGroup: GroupExtVm) {
    if (selectedGroup == null)
      return;

    // this.$store.commit("globals/setGroup", selectedGroup);
    vuex.globals.setGroup(selectedGroup);
    router.push("/users");
  }

  onEditGroup(selectedGroup: GroupExtVm) {
    if (selectedGroup == null)
      return;

    vuex.globals.setGroup(selectedGroup);
    this.editGroup = _.cloneDeep(selectedGroup);
    this.isNewGroup = false;
    this.showEditGroupDialog = true;
  }

  showDeleteConfirmationDlg(selectedGroup: GroupExtVm) {
    if (selectedGroup == null)
      return;

    vuex.globals.setGroup(selectedGroup);
    // this.deleteGroup = selectedGroup;
    this.showDeleteGroupDialog = true;
  }

  async onDeleteGroup() {
    // const index = this.groups.indexOf(this.deleteGroup);
    // this.groups.splice(index, 1);
    this.busy = true;
    await rest.url("groupAdmin/deleteGroup").post(this.selectedGroup)
      .then((response) => {
        this.showDeleteGroupDialog = false;
        this.updateGroupList();
      })
      .finally(() => this.busy = false)
  }

  onAddSubGroup(parentGroup: GroupExtVm) {
    if (parentGroup == null)
      return;

    vuex.globals.setGroup(parentGroup);
    this.editGroup = parentGroup;
    this.editSubGroup = _.cloneDeep(this.defaultSubGroup);
    this.showEditSubGroupDialog = true;
  }

  onEditSubGroup(subGroup: SubGroupVm, parentGroup: GroupExtVm) {
    if (subGroup == null || parentGroup == null)
      return;

    vuex.globals.setGroup(parentGroup);
    vuex.globals.setSubGroup(subGroup);
    this.editGroup = parentGroup;
    this.editSubGroup = _.cloneDeep(subGroup);
    this.showEditSubGroupDialog = true;
  }

  openDeleteSubGroupDialog(subGroup: SubGroupVm) {
    if (subGroup == null)
      return;

    vuex.globals.setSubGroup(subGroup);
    this.showDeleteSubGroupDialog = true;
  }

  async onDeleteSubGroup() {
    // const index = this.groups.indexOf(this.deleteGroup);
    // this.groups.splice(index, 1);
    this.busy = true;
    await rest.url("groupAdmin/deleteSubGroup").post(this.selectedSubGroup)
      .then((response) => {
        this.showDeleteSubGroupDialog = false;
        this.updateGroupList();
      })
      .finally(() => this.busy = false)
  }

  // Validation
  mailRules = [
    (v: string) => !!v || "Email is required",
    (v: string) =>
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Keine gültige E-Mail-Adresse"
  ];

  mailEditRules = [
    (v: string) =>
      !v ||
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) ||
      "Keine gültige E-Mail-Adresse"
  ];

  usernameRules = [
    (v: string) => !!v || "Benutzername erforderlich",
    (v: string) => (v && v.length >= 3) || "Benutzername zu kurz"
  ];

  keyRules = [
    //(v: string) => !!v || "Schlüssel erforderlich",
    (v: string) => !!v || "Aktivierungsschlüssel erforderlich",
    (v: string) => (v && v.length > 7) || "Schlüssel zu kurz"
  ];

  keyEditRules = [
    (v: string) =>
      !v || (v && (v.length == 0 || v.length > 7)) || "Schlüssel zu kurz"
  ];

  nameRules = [
    (v: string) => !!v || "Name erforderlich",
    (v: string) => (v && v.length > 1) || "Name zu kurz"
  ];

  passwordRules = [
    (v: string) => !!v || "Passwort erforderlich",
    (v: string) => (v && v.length > 7) || "Passwort ist zu kurz"
  ];
}
